<template>
  <v-card elevation="0">
    <v-card-title>{{ $t("ui_forms") }}</v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-for="(th, i) in FIELDS_DISPLAY" :key="i" class="text-left">
                {{ th }}
              </th>
              <th>{{ $t("ui_actions") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in forms" :key="row.id">
              <td v-for="(td, i) in FIELDS_DISPLAY" :key="i">
                {{ row[td] }}
              </td>
              <td style="min-width: 120px">
                <v-btn icon color="green" x-small @click="openEditModal(row)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon color="pink" x-small @click="deleteRow(row.id)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-actions>
      <v-btn color="accent" elevation="1" small @click="openAddModal">{{
        $t("ui_add")
      }}</v-btn>
    </v-card-actions>

    <AdminModalForm
      ref="AdminModalEditForm"
      :fieldsEdit="FIELDS_EDIT"
      rows="16"
      @onSave="onEdit"
    />
    <AdminModalForm
      ref="AdminModalCreateForm"
      :fieldsEdit="FIELDS_CREATE"
      rows="16"
      @onSave="onSave"
    />
  </v-card>
</template>

<script>
import api from "@/api";
import AdminModalForm from "@/apps/admin/components/AdminModalForm/AdminModalForm.vue";

import { mapState, mapActions } from "vuex";

const FIELDS_DISPLAY = [
  "id",
  "name",
  "type",
  "display",
  "processes",
  "validators",
];
const FIELDS_EDIT = ["name", "type", "display", "validators"];
const FIELDS_CREATE = ["name", "type", "display", "validators"];

export default {
  components: { AdminModalForm },
  data() {
    return {
      FIELDS_DISPLAY,
      FIELDS_EDIT,
      FIELDS_CREATE,
    };
  },
  computed: {
    ...mapState("core$common", ["node", "fields", "forms"]),
  },
  methods: {
    ...mapActions("core$common", ["getForms"]),
    deleteRow(id) {
      api
        .request({
          name: "deleteForm",
          pathParam: { nodeId: this.node.id, formId: id },
          showAlert: true,
        })
        .then(() => this.getForms());
    },
    onEdit(data) {
      api
        .request({
          name: "editForm",
          pathParam: { nodeId: this.node.id, formId: data.id },
          payload: data,
          showAlert: true,
        })
        .then(() => this.getForms());
    },
    onSave(data) {
      api
        .request({
          name: "createForm",
          pathParam: this.node.id,
          payload: data,
          showAlert: true,
        })
        .then(() => this.getForms());
    },
    openAddModal() {
      this.$refs.AdminModalCreateForm.open({});
    },
    openEditModal(data) {
      this.$refs.AdminModalEditForm.open(data);
    },
  },
};
</script>

<style lang="scss"></style>
